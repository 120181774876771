import { Box, Button, CircularProgress, Typography } from "@mui/material"
import Colors from "../../assests/style"


export const PrimaryButton = ({ title, icon, onClick, backgorundColor, color, borderRadius, sx, type,className ,fontSize}) => {
    return (
        <Button
        className={className ? "signupbtn" : ''}
            variant="outlined"
            onClick={onClick}
            sx={{
                textTransform: "capitalize",
                p: "10px 20px",


                backgroundColor: backgorundColor,
                color: color,
                borderRadius: borderRadius,
                ...sx
            }}
            type={type}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px"
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize:fontSize
                    }}
                >
                    {title}
                </Typography>
                {icon && icon}
            </Box>
        </Button>
    )
}

export const SecondaryButton = ({ title, icon, color, sx, fontWeight, p, onClick, bgColor, type, className, iconPosition, m, fullWidth, disabled ,Loader}) => {
    return (
        <Button
            disabled={disabled}
            type={type}
            onClick={onClick}
            className={className}
            variant="contained"
            fullWidth={fullWidth}
            sx={{
                backgroundColor: bgColor ? bgColor : "",
                textTransform: "capitalize",
                p: p ? p : "10px 20px",
                m: m ? m : "",
                border: `1px solid ${color}`,
                ":hover": {
                    // border: `1px solid ${color}`,
                }
                ,
                ...sx


            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px"
                }}
            >
                {iconPosition == 'start' && icon && icon}
                <Typography
                    sx={{
                        color: color ? color : ' white',
                        fontWeight: fontWeight,
                        display:'flex',
                        alignItems:'center'
                    }}
                >
                    {Loader ? <CircularProgress size={20} sx={{color:'#0D1426',padding:'0px 50px',pb:'1px'}} />  : title}
                </Typography>
                {iconPosition == 'end' && icon && icon}
            </Box>
        </Button>
    )
}