import React, { Fragment, useContext } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Colors from '../assests/style';
import { AuthContext } from '../Context/AuthContext';

function WebLayout() {
  const { user } = useContext(AuthContext);

  return (
    <Fragment >

      {user ? <Header /> :  <Header /> }
      <Box
        component={"main"}
        sx={{ overflow: 'hidden', flexGrow: 1,backgroundColor:Colors.backgroundColor1}}
      >
        <Outlet />
      </Box>
      <Footer />
  
    </Fragment>
  )
}

export default WebLayout