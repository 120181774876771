import { Box, Button, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import { Images } from '../../../assests'
import Colors from '../../../assests/style'

const PrivacyPolicy = () => {
  return (
    <div>
        <Box
        sx={{
          backgroundImage: `url("${Images.contactBg}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: {lg:"300px",md:'300px',sm:'220px',xs:'220px'}, // Adjust height as needed
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {/* <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: Colors.backgroundColor1
                    }}
                /> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1.5,
          }}
        >
          <Box
            component={"p"}
            className="banner-text heading-font"
            sx={{
              fontSize: { lg: "52px", md: "52px", sm: "52px", xs: "30px" },
              textAlign: "center",
              fontFamily: "Noto Sans, sans-serif",
            }}
          >
            {" "}
            Privacy Policy{" "}
          </Box>
       

          {/* <p style={{ fontSize: '25px', textAlign: 'center', color: 'white', fontFamily: "Noto Sans, sans-serif", }}>Homepage || <span style={{ color: Colors.secondary }}>Contact Us</span></p> */}
        </Box>
      </Box>
            <Grid container justifyContent={"center"} rowGap={"50px"} mt={15} mb={15}>
                    <Grid item md={10} sm={12} xs={12}>
                        <Grid
                            container
                            sx={{
                                background: Colors.contactGradient1,
                                borderRadius: "20px"
                            }}
                        >
                            
                            <Grid item md={12} sx={{ display: "flex" }}>
      <Box
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: 2,
          color:'white'
        }}
      >
      
      <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          1. Introduction
        </Typography>
        <Typography variant="body1">
          - At PrintMeUp, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information about you when you use our services, including customizing t-shirts with AI-generated images.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          2. Information We Collect
        </Typography>
        <Typography variant="body1">
          - **Personal Information:** When you create an account or make a purchase, we collect personal information such as your name, email address, shipping address, and payment details.
        </Typography>
        <Typography variant="body1">
          - **Design Content:** If you create custom t-shirts, we collect the images or designs you upload for printing. This includes AI-generated images and any other content you choose to use for your design.
        </Typography>
        <Typography variant="body1">
          - **Usage Information:** We automatically collect information about how you interact with our website, such as your IP address, browser type, device information, and actions you take on the site.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1">
          - **To Process Orders:** We use your personal information to process your orders, handle payments, and ship your products.
        </Typography>
        <Typography variant="body1">
          - **To Improve Our Services:** We may use your information to personalize your experience, analyze trends, and improve the performance of our website.
        </Typography>
        <Typography variant="body1">
          - **To Communicate with You:** We may send you order confirmations, customer support communications, and promotional emails (if you’ve opted in).
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          4. Data Retention
        </Typography>
        <Typography variant="body1">
          - We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, such as processing your orders, complying with legal obligations, or resolving disputes.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          5. Sharing Your Information
        </Typography>
        <Typography variant="body1">
          - **Service Providers:** We may share your information with trusted third-party service providers who help us operate our business, such as payment processors, shipping carriers, and email marketing services.
        </Typography>
        <Typography variant="body1">
          - **Legal Compliance:** We may disclose your information if required to do so by law or in response to legal requests, such as subpoenas or government investigations.
        </Typography>
        <Typography variant="body1">
          - **Business Transfers:** In the event of a merger, acquisition, or sale of our business, your information may be transferred as part of that transaction.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          6. Cookies and Tracking Technologies
        </Typography>
        <Typography variant="body1">
          - We use cookies and other tracking technologies to enhance your experience on our website, such as remembering your preferences and analyzing site usage. You can control cookie settings through your browser, but disabling cookies may affect certain features of the site.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          7. Your Rights and Choices
        </Typography>
        <Typography variant="body1">
          - **Access and Update Information:** You can access, update, or delete your personal information by logging into your account or contacting our customer service team.
        </Typography>
        <Typography variant="body1">
          - **Opt-Out of Marketing Communications:** If you no longer wish to receive promotional emails from us, you can unsubscribe by following the instructions in the email or contacting us directly.
        </Typography>
        <Typography variant="body1">
          - **Data Portability:** You may request a copy of the personal information we hold about you in a machine-readable format.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          8. Data Security
        </Typography>
        <Typography variant="body1">
          - We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of electronic transmission or storage is 100% secure, and we cannot guarantee absolute security.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          9. Children's Privacy
        </Typography>
        <Typography variant="body1">
          - Our services are not intended for children under the age of 13, and we do not knowingly collect personal information from children. If we learn that we have collected personal information from a child under 13, we will take steps to delete that information.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          10. Changes to this Privacy Policy
        </Typography>
        <Typography variant="body1">
          - We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically to stay informed about how we protect your information.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          11. Contact Us
        </Typography>
        <Typography variant="body1">
          - If you have any questions or concerns about this Privacy Policy, please contact us at [Insert contact details].
        </Typography>
      </Box>
    </Grid>
                        </Grid>
                    </Grid>
                 
                </Grid>

    </div>
  )
}

export default PrivacyPolicy
