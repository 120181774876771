import { Box } from '@mui/material'
import React from 'react'
import { Images } from '../../../assests'

const Chat = () => {
    return (
        <>
           <Box
        sx={{
          backgroundImage: `url("${Images.contactBg}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: {lg:"300px",md:'300px',sm:'220px',xs:'220px'}, // Adjust height as needed
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {/* <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: Colors.backgroundColor1
                    }}
                /> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1.5,
          }}
        >
          <Box
            component={"p"}
            className="banner-text heading-font"
            sx={{
              fontSize: { lg: "52px", md: "52px", sm: "52px", xs: "30px" },
              textAlign: "center",
              fontFamily: "Noto Sans, sans-serif",
            }}
          >
            {" "}
            Meow{" "}
          </Box>
          <Box
            component={"span"}
            className="banner-text heading-font"
            sx={{
              fontSize: { lg: "52px", md: "52px", sm: "52px", xs: "30px" },
              textAlign: "center",
              fontFamily: "Noto Sans, sans-serif",
            }}
          >
            {" "}
            Chat{" "}
          </Box>

          {/* <p style={{ fontSize: '25px', textAlign: 'center', color: 'white', fontFamily: "Noto Sans, sans-serif", }}>Homepage || <span style={{ color: Colors.secondary }}>Contact Us</span></p> */}
        </Box>
      </Box>
        <div style={{marginTop:'80px' ,width:'90%',margin:'80px auto'}}>
            <iframe
                src={'https://tawk.to/chat/67358a912480f5b4f59dd31a/1ickiaioe'}
                width="100%"
                height="500px"
                style={{border:'none'}}
                >
            </iframe>

        </div>
        </>
    )
}

export default Chat
