import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
} from "@mui/material";
import React, { useEffect, useState, useRef, useContext } from "react";
import background from "../../../assests/images/mayaccountbg.png";
import Colors from "../../../assests/style";
import { useForm } from "react-hook-form";
import { SecondaryButton } from "../../../components/Buttons";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import OrderServices from "../../../services/OrderServices";
import { useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import { Images, NextIcon, PrevIcon } from "../../../assests";
import { AuthContext } from "../../../Context/AuthContext";

const Collection = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [successMessage, setSuccessMessage] = useState(null);
  const [collections, setCollections] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [prevHover, setPrevHover] = useState(false);
  const [nextHover, setNextHover] = useState(false);
  const scrollContainerRef = useRef(null);
  const [pageLimit, setPageLimit] = useState(5)
  const [loadingStates, setLoadingStates] = useState({});
  const { user, setUser } = useContext(AuthContext);
  const [dataLoader, setDataLoader] = useState(false)

  const handleImageLoad = (index) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };

  const useStyles = styled({
    blurredBackground: {
      filter: "blur(12px)", // Apply the blur effect
      transition: "filter 0.3s ease", // Smooth transition
    },
    popupImage: {
      width: "100%",
      maxWidth: "300px",
      margin: "0 auto",
    },
    popupButton: {
      backgroundColor: "#00a8ff", // Same button color
      color: "#fff",
      marginTop: "20px",
    },
  });

  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGetCollections = async (id,limit) => {
    setLoading(true);
    let params = {
      id: id,
      limit :limit ? limit : pageLimit,
      page:1
    };
    try {
      const { data, responseCode, message } =
        await OrderServices.getCollectionList(params);
      console.log(data.orders);
    
      setCollections(data.collections);
  
      setLoading(false);
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };

  const getCategories = async () => {
    setLoading(true);
    try {
      const { data, responseCode, message } =
        await OrderServices.getCollectionCategories();
      console.log(data.orders);
      setCategories(data.categories);
      setLoading(false);
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins, sans-serif", // You can change this to your preferred font
    },
    components: {
      MuiStepLabel: {
        styleOverrides: {
          label: {
            color: "rgba(255, 255, 255, 0.7)",
            fontFamily: "Poppins, sans-serif",
            "&.Mui-active": {
              color: "#ffffff",
            },
            "&.Mui-completed": {
              color: "#ffffff",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-root": {
              color: "white", // Text color,
              fontFamily: "Poppins, sans-serif",
            },
            "& .MuiInputLabel-root": {
              color: "white", // Label color
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "white", // Underline color when unfocused
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "white", // Underline color when focused
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white", // Border color when unfocused
              },
              "&:hover fieldset": {
                borderColor: "white", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "white", // Border color when focused
              },
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            color: "white", // Text color for input
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "white", // Border color for input
          },
          root: {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "white", // Text color for label
            borderColor: "white",
            "&.Mui-focused": {
              color: "white",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "black", // Text color for menu items
            backgroundColor: "white", // Background color for menu items
            "&.Mui-selected": {
              backgroundColor: "rgba(255, 255, 255, 0.2)", // Background color for selected item
            },
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)", // Background color for hover
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "white", // Color for dropdown arrow
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#1976d2",
      },
      background: {
        default: "#333333",
      },
    },
  });

  const onSubmit = (data) => {
    // Send the data to your backend here
    console.log(data);
    setSuccessMessage("Thank you for your message!");
    // Reset the form
    setTimeout(() => {
      setSuccessMessage(null);
    }, 3000);
  };

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200; // Adjust this value to control scroll distance
      scrollContainerRef.current.scrollBy({
        left: direction === "next" ? scrollAmount : -scrollAmount,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    handleGetCollections();
    getCategories();
  }, []);

    useEffect(() => {
        handleGetCollections()
        getCategories()
    }, [])

    return (
        <div>
            <div>
                {/* Main Content */}


                {/* MUI Dialog for Popup */}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    slotProps={{
                        backdrop: {
                            sx: {
                                backdropFilter: "blur(8px)",
                                backgroundColor: "transparent",
                            }

                        },


                    }}
                    PaperProps={{
                        sx: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none'
                        }
                    }}
                >
                    <DialogContent className={classes.blurredBackground}>
                        <Box
                            sx={{
                                backgroundColor: 'transparent',
                                padding: '20px',
                                borderRadius: '8px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={selectedImage} // Replace with actual image
                                alt="Sports & Teams"
                                width={'180px'}
                                className={classes.popupImage}
                            />
                            {localStorage.getItem('user') && <Box mt={1}>
                                <SecondaryButton
                                    onClick={() => {
                                        navigate(
                                            `/product-final`,
                                            { state: selectedImage }
                                        );
                                        localStorage.removeItem('saveDesign')
                                        localStorage.setItem('gallery', JSON.stringify([
                                            { imageUrl: selectedImage, width: 100, height: 100, rotation: 0, top: 20, left: 20, borderRadius: 10 }
                                        ]));
                                        localStorage.setItem('designArray', JSON.stringify({
                                            frontText: [],
                                            backText: [],
                                            frontPic: [{ imageUrl: selectedImage, width: 100, height: 100, rotation: 0, top: 20, left: 20, borderRadius: 10, clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' }],
                                            backPic: [],
                                            color: 'white'
                                        }));
                                    }}
                                    p={"15px 30px"}
                                    fontWeight={'bold'}
                                    icon={<EastIcon />}
                                    color={'#0F172A'}
                                    sx={{background:"linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)",borderRadius:'15px',border:"none"}}
                                    title={'Start My Design'}
                                />
                            </Box>}
                        </Box>
                    </DialogContent>
                </Dialog>
            </div>

        {/* MUI Dialog for Popup */}
        <Dialog
          open={open}
          onClose={handleClose}
          slotProps={{
            backdrop: {
              sx: {
                backdropFilter: "blur(8px)",
                backgroundColor: "transparent",
              },
            },
          }}
          PaperProps={{
            sx: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <DialogContent className={classes.blurredBackground}>
            <Box
              sx={{
                backgroundColor: "transparent",
                padding: "20px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={selectedImage} // Replace with actual image
                alt="Sports & Teams"
                width={"180px"}
                className={classes.popupImage}
              />
              {localStorage.getItem('user') && <Box mt={1}>
                <SecondaryButton
                  onClick={() => {
                    navigate(`/product-final`, { state: selectedImage });
                    localStorage.removeItem('saveDesign')
                    localStorage.setItem(
                      "gallery",
                      JSON.stringify([
                        {
                          imageUrl: selectedImage,
                          width: 100,
                          height: 100,
                          rotation: 0,
                          top: 20,
                          left: 20,
                          borderRadius: 10,
                        },
                      ])
                    );
                    localStorage.setItem(
                      "designArray",
                      JSON.stringify({
                        frontText: [],
                        backText: [],
                        frontPic: [
                          {
                            imageUrl: selectedImage,
                            width: 100,
                            height: 100,
                            rotation: 0,
                            top: 20,
                            left: 20,
                            borderRadius: 10,
                            clipPath:
                              "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                          },
                        ],
                        backPic: [
                          
                        ],
                        color: "white",
                      })
                    );
                  }}
                  p={"15px 30px"}
                  fontWeight={"bold"}
                  icon={<EastIcon />}
                  color={"#0F172A"}
                  sx={{background:"linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)",borderRadius:'15px',border:"none"}}

                  title={"Start My Design"}
                />
              </Box>}
            </Box>
          </DialogContent>
        </Dialog>
    

      <Box
        sx={{
          backgroundImage: `url("${Images.contactBg}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: {lg:"300px",md:'300px',sm:'220px',xs:'220px'}, // Adjust height as needed
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            component={"p"}
            className="banner-text heading-font"
            sx={{
              fontSize: { lg: "52px", md: "52px", sm: "52px", xs: "30px" },
              textAlign: "center",
              fontFamily: "Noto Sans, sans-serif",
            }}
          >
            {" "}
            Collections{" "}
          </Box>
        </Box>
      </Box>

      <ThemeProvider theme={theme}>
        {/* <Grid
                    container
                    mt={7}
                    md={12}
                    lg={12}
                    justifyContent={'center'}
                    gap={2}

                    flexWrap={'nowrap'}
                    sx={{
                        display: { lg: 'none', md: 'flex', sm: 'flex', xs: "flex" },
                        overflowX: 'scroll', // Allows horizontal scrolling
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        gap: 2,
                        overflowX: 'scroll', // Allows horizontal scrolling
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },

                        width: { lg: '60%', md: '60%', sm: '95%', xs: '95%' }

                    }}>
                        <Chip

                            label={'All'}
                            onClick={() => {
                                setSelectedCategory(null);
                                handleGetCollections(null);
                            }}
                            variant={!selectedCategory ? 'filled' : 'outlined'}
                            sx={{
                                fontWeight: 'bold',
                                borderRadius: '24px',
                                borderColor: '#00D4FF',
                                color: !selectedCategory ? '#fff' : '#00D4FF',
                                backgroundColor: !selectedCategory ? '#00D4FF' : 'transparent',
                                '&:hover': {
                                    backgroundColor: !selectedCategory ? '#00BBD4' : 'transparent',
                                },
                            }}
                        />
                        {categories.map((category) => (
                            <Chip
                                key={category._id}
                                label={category.name}
                                onClick={() => {
                                    setSelectedCategory(category._id);
                                    handleGetCollections(category._id);
                                }}
                                variant={selectedCategory === category._id ? 'filled' : 'outlined'}
                                sx={{
                                    fontWeight: 'bold',
                                    borderRadius: '24px',
                                    borderColor: '#00D4FF',
                                    color: selectedCategory === category._id ? '#fff' : '#00D4FF',
                                    backgroundColor: selectedCategory === category._id ? '#00D4FF' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: selectedCategory === category._id ? '#00BBD4' : 'transparent',
                                    },
                                }}
                            />
                        ))}
                    </Box>
                </Grid> */}
        <Container>
          <Grid py={8}  container md={12} lg={12} justifyContent={"center"} sx={{flexDirection:(collections.length > 0 && categories.length > 0) ? "column" : 'row',alignItems:(collections.length > 0 && categories.length > 0) ? 'center' : ''}}>
            <Grid
              item
              md={12}
              display={'flex'}
              justifyContent={"center"}
            //   mt={6}
            >
              <Box
                component={"p"}
                className="heading-font"
                sx={{
                  color: Colors.white,
                  WebkitTextFillColor: "white",
                  fontSize: { lg: "40px", md: "40px", sm: "40px", xs: "24px" },

                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Explore 
                <br />{" "}
                <Box
                  component={"span"}
                  className="banner-text"
                  sx={{
                    fontSize: {
                      lg: "40px",
                      md: "40px",
                      sm: "40px",
                      xs: "24px",
                    },
                    fontWeight: "bold",
                  }}
                >
                 Collections
                </Box>{" "}
                <br />
              </Box>
            </Grid>
           
{   ( categories.length > 0) ?    
 <>   <Grid item xs={12} sx={{ position: "relative", mt: 4 }}>
              <Grid container justifyContent="center">
              <Grid
                  item
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    // overflowX: "auto",
                    position: "relative",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {/* Previous Button */}
                  <IconButton
                    onMouseEnter={() => setPrevHover(true)}
                    onMouseLeave={() => setPrevHover(false)}
                    sx={{
                      width: "30px",
                      height: "30px",
                      background: Colors.secondary1,
                      borderRadius: "50%",
                      display: { xs: "flex", sm: "flex" }, // Hide on small screens
                      position: "absolute",
                      top: "45%",
                      left: "10px",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                      ":hover": {
                        border: `1px solid ${Colors.secondary1}`,
                      },
                    }}
                    onClick={() => handleScroll("prev")}
                  >
                    <PrevIcon prevHover={prevHover}/>
                  </IconButton>

                  {/* Scrollable Container */}
                  <Box
                    className="scrollable_div"
                    ref={scrollContainerRef}
                    sx={{
                      display: "flex",

                      flexWrap: "nowrap",
                      overflowX: "auto",
                      margin: '0 auto',
                      gap: 2,
                      width: { lg: "82%", md: '82%', sm: '70%', xs: "70%" },
                      padding: { xs: "0 10px", sm: "0 50px" },
                    }}
                  >
                    <Chip
                      label="All"
                      onClick={() => {
                        setSelectedCategory(null);
                        handleGetCollections(null);
                      }}
                      variant={!selectedCategory ? "filled" : "outlined"}
                      sx={{
                        fontFamily: "Maven Pro",
                        fontSize: "16px",
                        padding: "10px 15px",
                        fontWeight: "700",
                        borderRadius: "10px",
                        borderColor: "#00D4FF",
                        color: !selectedCategory ? "#fff" : "#00D4FF",
                        background: !selectedCategory
                          ? Colors.contactGradient2
                          : "transparent",
                      }}
                    />
                    {categories.map((category) => (
                      <Chip
                        key={category._id}
                        label={category.name}
                        onClick={() => {
                          setSelectedCategory(category._id);
                          handleGetCollections(category._id);
                        }}
                        variant={
                          selectedCategory === category._id
                            ? "filled"
                            : "outlined"
                        }
                        sx={{
                          fontFamily: "Maven Pro",
                          fontSize: "16px",
                          padding: "10px 15px",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          borderColor: "#00D4FF",
                          color:
                            selectedCategory === category._id
                              ? "#fff"
                              : "#00D4FF",
                          background:
                            selectedCategory === category._id
                              ? Colors.contactGradient2
                              : "transparent",
                        }}
                      />
                    ))}
                  </Box>

                  {/* Next Button */}
                  <IconButton
                  onMouseEnter={() => setNextHover(true)}
                  onMouseLeave={() => setNextHover(false)}
                    sx={{
                      width: "30px",
                      height: "30px",
                      background: Colors.secondary1,
                      borderRadius: "50%",
                      display: { xs: "flex", sm: "flex" }, // Hide on small screens
                      position: "absolute",
                      top: "45%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                      ":hover": {
                        border: `1px solid ${Colors.secondary1}`,
                      },
                    }}
                    onClick={() => handleScroll("next")}
                  >
                    <NextIcon nextHover={nextHover} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            
           <Grid item md={12} lg={12}>
           <Grid container>
      <Grid item md={12} mt={6}>
        <Grid
          container
          sx={{
            justifyContent: { lg: "flex-start", md: "flex-start", xs: "center" },
          }}
          spacing={"20px"}
        >
          {collections?.length > 0 ? (
  collections.map((item, index) => {
    const isLoaded = loadingStates[index] || false;

    return (
      <Grid item sx={{ width: "auto" }} key={index}>
        <Box
        component={'div'}
        onClick={() => {
          setOpen(true);
          setSelectedImage(item?.image);
        }}
          sx={{
            backgroundColor: "#0f4363",
            borderRadius: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "210px",
            height: "210px",
            position: "relative",
            cursor:'pointer'
          }}
        >
          {!isLoaded && (
            <Box
              component={"img"}
              src={Images.loader}
              width={"100px"}
              height={"100px"}
            />
          )}
          {item?.image ? (
            <Box
            className="image-selection"
              component={"img"}
              onLoad={() => handleImageLoad(index)}
              
              style={{
                cursor: "pointer",
                objectFit: "contain",
                borderRadius: "15px",
                display: isLoaded ? "block" : "none",
              }}
              src={item?.image}
              width={"210px"}
              height={"210px"}
            />
          ) : (
            !isLoaded &&   <Box component={'img'} src={Images.loader} width={'100px'} height={'100px'} />
          )}
        </Box>
      </Grid>
    );
  })
) : (
<Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                          <Box
                            sx={{
                              mt: 5,
                              fontSize: "18px",
                              color: "gray",
                              textAlign: "center",
                            }}
                          >
                            No Images Available
                          </Box>
                        </Grid>
)}

        </Grid>
      </Grid>
    </Grid>
            </Grid> </> : <Box component={'img'} src={Images.loader} sx={{mt:5,textAlign:'center'}} width={'200px'} height={'200px'} />
}           { (collections.length > 0 && categories.length > 0 ) && <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="text"
                  className="heading-font"
                  color="primary"
                  onClick={()=> {setPageLimit(pageLimit + 5);handleGetCollections(selectedCategory?._id,pageLimit+5); } }
                  sx={{
                    background: Colors.contactGradient2,
                    "-webkit-text-fill-color": "rgba(15, 23, 42, 1)",
                    color: "rgba(15, 23, 42, 1)", // New text color on hover
                    fontWeight: 600,
                    textTransform: "capitalize",
                    mt: 4.5,
                    p: "10px 40px",
                    fontSize: "17px",
                    borderRadius: "15px",
                    "&:hover": {
                      color: "#101A33",
                    },
                  }}
                >
                  View More
                </Button>
              </Box>
            </Grid>}
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default Collection;
