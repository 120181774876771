import { Box, Button, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import { Images } from '../../../assests'
import Colors from '../../../assests/style'

const TermsAndConditions = () => {
  return (
    <div>
       <Box
        sx={{
          backgroundImage: `url("${Images.contactBg}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: {lg:"300px",md:'300px',sm:'220px',xs:'220px'}, // Adjust height as needed
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {/* <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: Colors.backgroundColor1
                    }}
                /> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1.5,
          }}
        >
          <Box
            component={"p"}
            className="banner-text heading-font"
            sx={{
              fontSize: { lg: "52px", md: "52px", sm: "52px", xs: "30px" },
              textAlign: "center",
              fontFamily: "Noto Sans, sans-serif",
            }}
          >
            {" "}
            Terms & Conditions{" "}
          </Box>
         

          {/* <p style={{ fontSize: '25px', textAlign: 'center', color: 'white', fontFamily: "Noto Sans, sans-serif", }}>Homepage || <span style={{ color: Colors.secondary }}>Contact Us</span></p> */}
        </Box>
      </Box>
            <Grid container justifyContent={"center"} rowGap={"50px"} mt={15}  mb={15}>
                    <Grid item md={10} sm={12} xs={12}>
                        <Grid
                            container
                            sx={{
                                background: Colors.contactGradient1,
                                borderRadius: "20px"
                            }}
                        >
                            
                            <Grid item md={12} sx={{ display: "flex" }}>
      <Box
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: 2,
          color:'white'
        }}
      >
      
        <Typography className='heading-font' variant="h6" sx={{ mt: 4}}>
          1. Use of Service
        </Typography>
        <Typography variant="body1">
          - PrintMeUp allows users to design and purchase custom t-shirts using AI-generated images.
        </Typography>
        <Typography variant="body1">
          - By using our website, you agree to only upload content that is legal, non-infringing, and in accordance with our guidelines. You are responsible for all content uploaded, including AI-generated images, and for ensuring that it does not violate copyright, trademark, or other intellectual property laws.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          2. User-Generated Content
        </Typography>
        <Typography variant="body1">
          - You retain the copyright to any custom designs you create, but by submitting these designs, you grant PrintMeUp a non-exclusive, royalty-free license to use, reproduce, and display the designs for marketing and promotional purposes on our website and in advertisements.
        </Typography>
        <Typography variant="body1">
          - You represent and warrant that any AI-generated image or content you upload does not infringe on the intellectual property rights of others.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          3. Ordering and Payment
        </Typography>
        <Typography variant="body1">
          - All t-shirt orders are processed upon receipt of full payment. Prices are listed on the website and are subject to change.
        </Typography>
        <Typography variant="body1">
          - We accept payments through [Insert payment methods, e.g., PayPal, credit/debit cards, etc.].
        </Typography>
        <Typography variant="body1">
          - Once an order is placed, you will receive an order confirmation email. However, we reserve the right to cancel or refuse orders if fraud is suspected or if the order does not meet our terms.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          4. Customization Guidelines
        </Typography>
        <Typography variant="body1">
          - While we aim to offer flexibility with t-shirt customization, we reserve the right to reject any design deemed inappropriate, offensive, or in violation of any applicable laws or regulations.
        </Typography>
        <Typography variant="body1">
          - AI-generated images used for customization must comply with our content policy and cannot be obscene, discriminatory, or promote harmful ideologies.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          5. Shipping and Delivery
        </Typography>
        <Typography variant="body1">
          - Shipping times are estimated and may vary based on location. PrintMeUp is not responsible for delays caused by shipping carriers.
        </Typography>
        <Typography variant="body1">
          - All orders are shipped to the address provided at checkout. Please ensure that your address is correct to avoid delivery issues.
        </Typography>

        <Typography variant="h6"  className='heading-font' sx={{ mt: 4 }}>
          6. Returns and Exchanges
        </Typography>
        <Typography variant="body1">
          - Since t-shirts are custom-made, we do not accept returns or exchanges unless the product is defective or incorrect. If there is an issue with your order, please contact our customer support team within 7 days of receiving your product.
        </Typography>
        <Typography variant="body1">
          - Any returns or exchanges will be subject to the review of our customer service team.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          7. Intellectual Property
        </Typography>
        <Typography variant="body1">
          - All content on the website, including logos, images, text, and designs, are the property of PrintMeUp or its licensors and are protected by copyright laws. Users are not permitted to reproduce or redistribute any of the website's content without prior consent.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          8. Limitation of Liability
        </Typography>
        <Typography variant="body1">
          - PrintMeUp is not liable for any damages or losses arising from the use of our website, the ordering process, or the customization of t-shirts. We are not responsible for any issues with AI-generated images or for any third-party claims relating to the content you upload.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          9. Privacy Policy
        </Typography>
        <Typography variant="body1">
          - By using our services, you agree to our Privacy Policy, which outlines how we collect, use, and protect your personal information. We are committed to safeguarding your data and ensuring your privacy.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          10. Modifications to Terms
        </Typography>
        <Typography variant="body1">
          - PrintMeUp reserves the right to modify these Terms and Conditions at any time. We will notify users of significant changes via email or through a notice on our website. Continued use of the service after such changes will constitute acceptance of the modified terms.
        </Typography>

        <Typography variant="h6" className='heading-font' sx={{ mt: 4 }}>
          11. Governing Law
        </Typography>
        <Typography variant="body1">
          - These Terms and Conditions are governed by the laws of [Insert country or jurisdiction], without regard to its conflict of law principles. Any disputes related to these terms will be resolved in the courts located in [Insert city/country].
        </Typography>
      </Box>
    </Grid>
                        </Grid>
                    </Grid>
                 
                </Grid>

    </div>
  )
}

export default TermsAndConditions
