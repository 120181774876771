import {
  Box,
  Button,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useState } from "react";
import background from "../../../assests/images/mayaccountbg.png";
import Colors from "../../../assests/style";
import { useForm } from "react-hook-form";
import { SecondaryButton } from "../../../components/Buttons";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Images } from "../../../assests";
import { useLocation, useNavigate } from "react-router-dom";
import OrderServices from "../../../services/OrderServices";
import Swal from "sweetalert2";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';

const ContactUs = () => {
  const navigate = useNavigate()
  const [partnerModal, setPartnerModal] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const [successMessage, setSuccessMessage] = useState(null);

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins, sans-serif", // You can change this to your preferred font
    },
    components: {
      MuiStepLabel: {
        styleOverrides: {
          label: {
            color: "rgba(255, 255, 255, 0.7)",
            fontFamily: "Poppins, sans-serif",
            "&.Mui-active": {
              color: "#ffffff",
            },
            "&.Mui-completed": {
              color: "#ffffff",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-root": {
              color: "white", // Text color,
              fontFamily: "Poppins, sans-serif",
            },
            "& .MuiInputLabel-root": {
              color: "white", // Label color
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "white", // Underline color when unfocused
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "white", // Underline color when focused
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white", // Border color when unfocused
              },
              "&:hover fieldset": {
                borderColor: "white", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "white", // Border color when focused
              },
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            color: "white", // Text color for input
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "white", // Border color for input
          },
          root: {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "white", // Text color for label
            borderColor: "white",
            "&.Mui-focused": {
              color: "white",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "black", // Text color for menu items
            backgroundColor: "white", // Background color for menu items
            "&.Mui-selected": {
              backgroundColor: "rgba(255, 255, 255, 0.2)", // Background color for selected item
            },
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)", // Background color for hover
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "white", // Color for dropdown arrow
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#1976d2",
      },
      background: {
        default: "#333333",
      },
    },
  });

  const onSubmit = async (formData) => {
    setPartnerModal(false)
    try {
      let obj = {
        name: formData?.name,
        email: formData?.email,
        subject: formData?.subject,
        message: formData?.message,
      };

      const { message } = await OrderServices.SubmitMail(obj);
      console.log(message);

    
      Swal.fire({
        title: "Email Sent",

        icon: "success",
        background: "#0f172a", // Change to your desired background color
        color: "#fff", // Change to your desired text color
      }).then((result) => {
        if (result.isConfirmed) {
          // window.location.href = '/my-account'; // Navigate to the home page
        }
      });
        reset();
      
      
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error sending your message. Please try again later.",
        confirmButtonText: "OK",
      });
    }
  };
  return (
    <div>
      <Dialog
        open={partnerModal}
        onClose={() => setPartnerModal(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        sx={{
          ".MuiPaper-root": {
            background: '#0c1427',
            borderRadius: "12px",
          }
        }}
        maxWidth="sm" // Adjust the maxWidth as per your needs
      >

        <Box
          sx={{
            backgroundColor: '#0c1427',
            boxShadow: 24,
            p: 4,
            borderRadius: '12px',
            overflow: 'hidden'
          }}
        >
 <Box component={'div'} onClick={()=> setPartnerModal(false)} onTouchStart={()=> setPartnerModal(false)} sx={{ position: 'absolute', right: 10, top: 10, display: { lg: 'block', md: 'block', sm: 'block', xs: 'block', zIndex: 99999 } }} ><IconButton sx={{ color: 'white' }} ><CloseIcon sx={{ cursor: 'pointer' }} color="white" /></IconButton></Box>
          <Typography
            id="dialog-title"
            variant="h6"
            className="heading-font2"
            sx={{ color: 'white', textAlign: 'center' }}
            component="h2"
          >
            PURR... For Help
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} mt={2} mb={2}>
              {/* <Grid item xs={12}>
                  <TextField
                    size="small"
                    label="Name"
                    {...register('name', { required: true })}
                    error={!!errors.name}
                    sx={{
                      input: { color: 'white' },
                      label: { color: 'white' },
                      '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                    }}
                    helperText={errors.name ? 'Name is required' : ''}
                    fullWidth
                  />
                </Grid> */}
              <Grid item xs={12}>
                   <TextField
                    fullWidth
                    placeholder="Full Name"
                    variant="outlined"
                    size="small"
                    {...register("name", { required: "Name is required" })}
                    error={!!errors.name}
                    helperText={errors.name ? errors.name.message : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                           <PersonIcon sx={{fontSize:'15px',mt:'2px',color:'#192133'}} / >
                        </InputAdornment>
                      ),
                      style: {
                        color: "#0F172A",
                        fontSize: "16px",
                        borderRadius: 8, // Rounded corners
                      },
                    }}
                    sx={{
                      my: 0.5,
                      // backgroundColor: "#1E1E1E", // Dark background color
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "&.MuiFormControl-fullWidth": {
                        background: "transparent !important",
                        borderRadius: '10px !important'
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />
              </Grid>
              <Grid item xs={12}>
              <TextField
                    fullWidth
                    placeholder="Email"
                    variant="outlined"
                    size="small"
                    type="email"
                    {...register("email", { required: "Email is required" })}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                           <EmailIcon sx={{fontSize:'15px',mt:'2px',color:'#192133'}} / >
                        </InputAdornment>
                      ),
                      style: {
                        color: "#0F172A",
                        fontSize: "16px",
                        borderRadius: 8, // Rounded corners
                      },
                    }}
                    sx={{
                      my: 0.5,
                      backgroundColor: "#1E1E1E", // Dark background color
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "&.MuiFormControl-fullWidth": {
                        background: "transparent !important",
                        borderRadius: '10px !important'
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />
              </Grid>
              <Grid item xs={12}>
              <TextField
                    fullWidth
                    placeholder="Subject"
                    variant="outlined"
                    size="small"
                    {...register("subject", { required: "subject is required" })}
                    error={!!errors.subject}
                    helperText={errors.subject ? errors.subject.message : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                       
                        <ChatIcon sx={{fontSize:'15px',mt:'2px',color:'#192133'}} / >
                       
                        </InputAdornment>
                      ),
                      style: {
                        color: "#0F172A",
                        fontSize: "16px",
                        borderRadius: 8, // Rounded corners
                      },
                    }}
                    
                    sx={{
                      my: 0.5,
                      // backgroundColor: "#1E1E1E", // Dark background color
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "&.MuiFormControl-fullWidth": {
                        background: "transparent !important",
                        borderRadius: '10px !important'
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />
              </Grid>
              <Grid item xs={12}>
              <TextField
                    fullWidth
                    placeholder="Message"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={7}
                    {...register("message", { required: "message is required" })}
                    error={!!errors.message}
                    helperText={errors.message ? errors.message.message : ""}
                   
                    sx={{
                      my: 0.5,
                      // backgroundColor: "#1E1E1E", // Dark background color
                      "& .MuiOutlinedInput-root": {
                         borderRadius: '8px !important',
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "&.MuiFormControl-fullWidth": {
                        background: "transparent !important",
                        borderRadius: '12px !important'
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />

              </Grid>
              <Grid item xs={12}>
                <SecondaryButton
                  type="submit"
                  bgColor="#0bc1d9"
                  fullWidth
                  className="checkout-btn"
                  iconPosition="end"
                  icon={<ArrowForwardIcon sx={{ color: '#0C1427' }} />}
                  p="10px 20px"
                  fontWeight="bold"
                  color="#0C1427"
                  title="Submit"
                />
              </Grid>
            </Grid>
          </form>
        </Box>

      </Dialog>

      <Box
        sx={{
          backgroundImage: `url("${Images.contactBg}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: {lg:"300px",md:'300px',sm:'220px',xs:'220px'}, // Adjust height as needed
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {/* <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: Colors.backgroundColor1
                    }}
                /> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1.5,
          }}
        >
          <Box
            component={"p"}
            className="banner-text heading-font"
            sx={{
              fontSize: { lg: "52px", md: "52px", sm: "52px", xs: "30px" },
              textAlign: "center",
              fontFamily: "Noto Sans, sans-serif",
            }}
          >
            {" "}
            Contact{" "}
          </Box>
          <Box
            component={"span"}
            className="banner-text heading-font"
            sx={{
              fontSize: { lg: "52px", md: "52px", sm: "52px", xs: "30px" },
              textAlign: "center",
              fontFamily: "Noto Sans, sans-serif",
            }}
          >
            {" "}
            Us{" "}
          </Box>

          {/* <p style={{ fontSize: '25px', textAlign: 'center', color: 'white', fontFamily: "Noto Sans, sans-serif", }}>Homepage || <span style={{ color: Colors.secondary }}>Contact Us</span></p> */}
        </Box>
      </Box>
      <Box component={"section"} sx={{ py: 8 }}>

        <Grid container justifyContent={"center"} rowGap={"50px"}>
          <Grid item md={9.5} sm={11} xs={11}>
            <Grid
              container
              sx={{
                background: Colors.contactGradient1,
                borderRadius: "20px",
                flexDirection: {
                  lg: "row",
                  md: "row",
                  sm: "column-reverse",
                  xs: "column-reverse",
                },
              }}
            >
              <Grid item md={6} sx={{ margin: { lg: 0, md: '0', sm: '0 auto', xs: '0 auto' } }} >
                <Box>
                  <CardMedia
                    component={"img"}
                    src={Images.catImage}
                    sx={{
                      height: {
                        lg: "400px",
                        md: "400px",
                        sm: "400px",
                        xs: "360px",
                      },
                      width: {
                        lg: "400px",
                        md: "400px",
                        sm: "400px",
                        xs: "360px",
                      },
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ display: "flex" }}>
                <Box
                  sx={{
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: { lg: "flex-start", md: "flex-start", sm: 'center', xs: 'center ' },
                    gap: 2,
                    width: '100%'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "48px",
                        md: "48px",
                        sm: "48px",
                        xs: "24px",
                      },
                      fontWeight: 700,

                    }}
                  >
                    <span className="heading-font2" style={{ color: 'white' }}>Get </span>{" "}
                    <span className="heading-font" style={{ color: Colors.secondary1 }}> Support</span>{" "}

                  </Typography>

                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      onClick={() => setPartnerModal(true)}
                      sx={{
                        background: "linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)",
                        color: Colors.backgroundColor1,
                        textTransform: "capitalize",
                        p: "5px 27px",
                        fontSize: {
                          lg: "24px",
                          md: "24px",
                          sm: "24px",
                          xs: "17px",
                        },
                        fontWeight: 500,
                        borderRadius: "15px",
                        border: "1px solid transparent",
                        ":hover": {
                          border: `1px solid ${Colors.secondary1}`,
                          color: Colors.backgroundColor1,
                        },
                      }}
                    >
                      PURR....
                    </Button>
                    <Button
                      onClick={() => navigate('/chat')}
                      sx={{
                        background: "linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1)",
                        color: Colors.backgroundColor1,
                        textTransform: "capitalize",
                        p: "5px 10px",
                        fontSize: {
                          lg: "24px",
                          md: "24px",
                          sm: "24px",
                          xs: "17px",
                        },
                        fontWeight: 500,
                        borderRadius: "15px",
                        border: "1px solid transparent",
                        ":hover": {
                          border: `1px solid ${Colors.secondary1}`,
                          color: Colors.backgroundColor1,
                        },
                      }}
                    >
                      Meow Chat
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item md={10} sm={12} xs={11}>
            <Grid
              container
              sx={{
                background: Colors.contactGradient2,
                borderRadius: "20px",
              }}
            >
              <Grid item md={6} sx={{ display: "flex" }}>
                <Box
                  sx={{
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "48px",
                        md: "48px",
                        sm: "48px",
                        xs: "24px",
                      },
                      fontWeight: 700,
                      color: Colors.backgroundColor1,
                    }}
                  >
                    Print Me Up is Always
                    <br />
                    Ready to Help!
                  </Typography>
                 
                  <Button
                    sx={{
                      background: Colors.backgroundColor1,
                      color: Colors.white,
                      textTransform: "capitalize",
                      p: "15px 25px",
                      fontSize: {
                        lg: "24px",
                        md: "24px",
                        sm: "24px",
                        xs: "17px",
                      },
                      fontWeight: 500,
                      borderRadius: "15px",
                      border: "1px solid transparent",
                      ":hover": {
                        border: `1px solid ${Colors.backgroundColor1}`,
                        color: Colors.backgroundColor1,
                      },
                    }}
                  >
                    Chat Now
                  </Button>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <CardMedia
                    component={"img"}
                    src={Images.contactImg2}
                    sx={{
                      height: {
                        lg: "400px",
                        md: "400px",
                        sm: "400px",
                        xs: "360px",
                      },
                      width: {
                        lg: "400px",
                        md: "400px",
                        sm: "400px",
                        xs: "360px",
                      },
                      objectFit: "contain",
                      position: "relative",
                      float: "right",
                      bottom: "-18px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Box>
      {/* <ThemeProvider theme={theme}>
                <Box sx={{ width: { xs: '90%', sm: '70%', md: '70%' }, margin: '0 auto' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <Grid container spacing={2} mt={5} mb={5}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Your Name"
                                    {...register('name', { required: true })}
                                    error={!!errors.name}
                                    sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
                                    helperText={errors.name ? 'Name is required' : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Email Address"
                                    {...register('email', {
                                        required: true,
                                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                                    })}
                                    error={!!errors.email}
                                    sx={{
                                        input: { color: 'white' },
                                        label: { color: 'white' },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'white',
                                            '&:hover': {
                                                borderColor: 'white'
                                            }
                                        }
                                    }}
                                    helperText={errors.email ? 'Invalid email address' : ''}
                                    fullWidth
                                />
                            </Grid>


                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Phone Number"
                                    {...register('phone', {
                                        required: true,

                                    })}
                                    type='number'
                                    error={!!errors.phone}
                                    sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
                                    helperText={errors.phone ? 'Invalid phone number' : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>


                                <TextField
                                    label="Subject"
                                    {...register('subject', { required: true })}
                                    error={!!errors.subject}
                                    sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
                                    helperText={errors.subject ? 'Subject is required' : ''}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    label="Message"
                                    {...register('message', { required: true })}
                                    error={!!errors.message}
                                    sx={{ input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
                                    helperText={errors.message ? 'Message is required' : ''}
                                    multiline
                                    rows={6}
                                    fullWidth
                                />
                            </Grid>
                            {successMessage && (
                            <Grid item xs={12}>
                                <Typography variant="body1" color="green" gutterBottom>
                                    {successMessage}
                                </Typography>
                            </Grid>
                        )}
                            <Grid item xs={12}>
                                <SecondaryButton
                                    type={'submit'}
                                    bgColor={'#0bc1d9'} fullWidth={true} className={'checkout-btn'} iconPosition={'end'} icon={<ArrowForwardIcon sx={{ color: '#0C1427' }} />} p={"15px 40px"} fontWeight={'bold'} color={'#0C1427'} title={'Send Message'} />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </ThemeProvider> */}
    </div>
  );
};

export default ContactUs;
