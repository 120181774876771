const routes = {
    placeOrder:'/order/place/v2',
    userProfile:'/users/profile',
    getLibrary:'/users/library',
    addLibrary:'/users/addToLibrary',
    getOrderList:'order/listing',
    getPricing:'system/pricing',
    removeBg:'/system/removeBGV1',
    removeBgV2:"/system/removeBGV2",
    Collections:'system/collections',
    completeOrder:'/order/complete/v2',
    getCollectionList:'/collections',
    getCollectionCategories:'/collections/categories',
    SaveDesign:'/users/saveDesign',
    getDetail:'/users/designDetails',
    AddToCart:'/users/addToCart',
    CartData:'/users/cart',
    UpdateCart:'/users/updateCart',
    getDesigns:"/users/designs",
    PromoCode:"/system/vouchers/apply",
    getCreditList:'/users/creditHistory',
    CreditCheck:'/users/preGenerate',
    buyCredit:'/order/place/credit',
    completeCreditOrder:'/order/complete/credit',
    SubmitMail:'system/sendEmail',
    UpdateProfile:'/users/updateProfile'
 };
 
 export default routes