import { get, post, patch } from '../../services/';
import routes from './routes';

const OrderServices = {
  addLibrary: async (obj) => {
    const data = await post(routes.addLibrary, obj);
    return data;
  },
  SubmitMail: async (obj) => {
    const data = await post(routes.SubmitMail, obj);
    return data;
  },
  PromoCode: async (obj) => {
    const data = await post(routes.PromoCode, obj);
    return data;
  },
  completeCreditOrder: async (obj) => {
    const data = await post(routes.completeCreditOrder, obj);
    return data;
  },
  buyCredit: async (obj) => {
    const data = await post(routes.buyCredit, obj);
    return data;
  },
  SaveDesign: async (obj) => {
    const data = await post(routes.SaveDesign, obj);
    return data;
  },
  completeOrder: async (obj) => {
    const data = await post(routes.completeOrder, obj);
    return data;
  },
  CreditCheck: async (obj) => {
    const data = await post(routes.CreditCheck, obj);
    return data;
  },
  AddToCart: async (obj) => {
    const data = await post(routes.AddToCart, obj);
    return data;
  },
  removeBg: async (obj) => {
    const data = await post(routes.removeBg, obj);
    return data;
  },
  removeBgV2: async (obj) => {
    const data = await post(routes.removeBgV2, obj);
    return data;
  },
  placeOrder: async (obj) => {
    const data = await post(routes.placeOrder, obj);
    return data;
  },
  userProfile: async (params) => {
    const data = await get(routes.userProfile, params);
    return data;
  },
  getDetail: async (params) => {
    const data = await get(routes.getDetail, params);
    return data;
  },
  getCreditList: async (params) => {
    const data = await get(routes.getCreditList, params);
    return data;
  },
  CartData: async (params) => {
    const data = await get(routes.CartData, params);
    return data;
  },
  getDesigns: async (params) => {
    const data = await get(routes.getDesigns, params);
    return data;
  },
  UpdateCart: async (params) => {
    const data = await patch(routes.UpdateCart, params);
    return data;
  },
  UpdateProfile: async (params) => {
    const data = await patch(routes.UpdateProfile, params);
    return data;
  },
  getCollectionCategories: async (params) => {
    const data = await get(routes.getCollectionCategories, params);
    return data;
  },
  getCollectionList: async (params) => {
    const data = await get(routes.getCollectionList, params);
    return data;
  },
  Collections: async (params) => {
    const data = await get(routes.Collections, params);
    return data;
  },
  getPricing: async (params) => {
    const data = await get(routes.getPricing, params);
    return data;
  },
  getOrderList: async (params) => {
    const data = await get(routes.getOrderList, params);
    return data;
  },
  getLibrary: async (params) => {
    const data = await get(routes.getLibrary, params);
    return data;
  },
}

export default OrderServices