import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Stack,
  colors,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Images } from "../../assests";
import { useForm } from "react-hook-form";
import AuthServices from "../../services/AuthServices";
import { AuthContext } from "../../Context/AuthContext";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import OTPInput from "react-otp-input";
import Swal from "sweetalert2";
import { updatePassword } from "firebase/auth";

const SignInModal = ({ open, handleClose, handleGoogleLogin, screen, getCartData }) => {


  const [currentState, setCurrentState] = useState(screen);
  const { user, setUser } = useContext(AuthContext);
  const [showPasswordLogin, setShowPasswordLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [error, setError] = useState(false);
  const [otpEnable, setOtpEnable] = useState(false);
  const [otp, setOtp] = useState("");
  const [userObj, setUserObj] = useState(null);
  const [otpToken, setOtpToken] = useState(null);
  const [otpError, setOtpError] = useState(false)
  const [otpEnable2, setOtpEnable2] = useState(false);
  const [otp2, setOtp2] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(false)
  const [currentData, setCurrentData] = useState(null)

  const [otpToken2, setOtpToken2] = useState(null);
  const [otpError2, setOtpError2] = useState(false)
  const [emailConfirmation, setEmailConfirmation] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  const {
    register,
    handleSubmit,
    getValues,
    setValue,

    formState: { errors },
    reset,
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    getValues: getValues2,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    setValue: setValue3,
    getValues: getValues3,
    reset: reset3,
    formState: { errors: errors3 },
  } = useForm();
  const {
    register: register4,
    handleSubmit: handleSubmit4,
    setValue: setValue4,
    getValues: getValues4,
    reset: reset4,
    formState: { errors: errors4 },
  } = useForm();
  useEffect(() => {

    setCurrentState(screen);
    console.log(screen, 'screen');

  }, [screen]);
  useEffect(() => {

    setCurrentState(screen);
    console.log(screen, 'screen');

  }, []);
  const handleLogin = async (sendData, result) => {
    console.log(sendData, "data");
    try {
      let obj = {
        email: sendData.login,
        password: sendData.password,
      };
      const data = await AuthServices.authLoginViaEmail(obj);
      console.log(data);
      if (data?.responseCode == 200) {
        localStorage.setItem("authToken", JSON.stringify(data?.data?.token));
        localStorage.setItem("user", JSON.stringify(data?.data));
        let userInfo = localStorage.getItem("user");
        setError("");
        setUser(data?.data);
        getCartData()
        handleClose();
        setOtpError(false)
        setOtpError2(false)
        setOtp('')
        setOtp2('')
        setConfirmPassword(false)
        setOtpEnable(false)
        setOtpEnable2(false)
        setEmailConfirmation(false)
        reset()

      }
    } catch (error) {
      setError(error);
      console.log(error);
    } finally {
    }
  };
  const handleRegister = async (sendData, result) => {
    setTimer(60);
    console.log(sendData, "data");
    setCurrentData(sendData)
    try {
      let obj = {
        name: sendData.name,
        email: sendData.email,
        password: sendData.password,
      };
      setUserObj(obj);
      const data = await AuthServices.authRegisterViaEmail(obj);
      console.log(data);
      if (data.responseCode == 200) {
        localStorage.setItem("authToken", JSON.stringify(data?.data?.token));
        localStorage.setItem("user", JSON.stringify(sendData));
        let userInfo = localStorage.getItem("user");
        setUser(data?.data);
        reset2()
        getCartData()
        setConfirmPassword(false)
        setOtpEnable(false)
        setOtpEnable2(false)
        setEmailConfirmation(false)
        setCurrentState('register')
        setOtp('')
        setOtp2('')
        setOtpError(false)
        setOtpError2(false)
        handleClose();
      }
      if (data?.responseCode == 206) {
        setOtpError(false)
       
        setOtpEnable(true);
      }
    } catch (error) {

      Swal.fire({
        title: 'An error occurred!',
        text: error,
        icon: 'error',
        confirmButtonText: 'OK'
      });

    } finally {
    }
  };
  const SubmitOTP = async (sendData, result) => {
    console.log(sendData, "data");
    try {
      let obj = {
        ...userObj,
        otp: otp,
      };

      const data = await AuthServices.authRegisterViaEmail(obj);
      console.log(data);
      if (data.responseCode == 206) {
        setOtpToken(data?.otp_token);
        setOtpEnable(false)
        setOtpError(false)
        setOtp('')
        Register(sendData, data?.data?.otp_token);
      }
    } catch (error) {
      setOtpError(true)
    } finally {
    }
  };
  const SubmitOTP2 = async (sendData, result) => {
    console.log(sendData, "data");
    try {
      let obj = {
        email: getValues3('email'),
        otp: otp2,
      };

      const data = await AuthServices.SendOtp(obj);
      console.log(data, 'tesetttt');
      if (data.responseCode == 206) {

        setOtpToken2(data?.data?.otp_token);
        setOtpEnable2(false)
        setOtpError2(false)
        setOtp2('')
        setConfirmPassword(true)
        // Register(sendData, data?.data?.otp_token);
      }
    } catch (error) {
      setOtpError2(true)
    } finally {
    }
  };
  const UpdatePassword = async (sendData, result) => {
    console.log(otpToken2, "otpToken2");
    try {
      let obj = {
        otp_token: otpToken2,
        email: getValues3('email'),
        password: getValues4('password'),
        confirm_password: getValues4('confirmPassword'),
      };

      const data = await AuthServices.SendOtp(obj);
      console.log(data);
      if (data.responseCode == 200) {
        setOtp('')
        setOtp2('')
        handleClose()
        setCurrentState('register');
        setOtpEnable(false)
        setOtpEnable2(false)
        setOtpError(false)
        setOtpError2(false)
        reset4()
        setConfirmPassword(false)
        setCurrentState('register')
        Swal.fire({
          title: data?.message,

          icon: "success",
          background: "#0f172a", // Change to your desired background color
          color: "#fff", // Change to your desired text color
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.href = '/my-account'; // Navigate to the home page
          }
        });

      }
    } catch (error) {
      setOtpError(true)
    } finally {
    }
  };
  const SendOtp = async (sendData, result) => {
    console.log(sendData, "data");
    setTimer(60);
    try {
      let obj = {
        email: getValues3('email')
      };

      const data = await AuthServices.SendOtp(obj);
      console.log(data);
      if (data.responseCode == 206) {
        setCurrentState(null)
        setEmailConfirmation(false)
        setOtpEnable2(true)
        setEmailError(false)
      }
    } catch (error) {

      setEmailError(true)
    } finally {
    }
  };
  const Register = async (sendData, token) => {
    console.log(sendData, "data");
    try {
      let obj = {
        ...userObj,
        otp_token: token,
      };

      const data = await AuthServices.authRegisterViaEmail(obj);
      console.log(data);
      if (data.responseCode == 200) {
        localStorage.setItem("authToken", JSON.stringify(data?.data?.token));
        localStorage.setItem("user", JSON.stringify(data?.data));
        let userInfo = localStorage.getItem("user");
        setUser(data?.data);
        getCartData()
        setCurrentState('register');
        setConfirmPassword(false)
        setOtpEnable(false)
        setOtpEnable2(false)
        setEmailConfirmation(false)
        setOtp('')
        setOtp2('')
        setOtpError(false)
        setOtpError2(false)
        handleClose();
      }
    } catch (error) {
    } finally {
    }
  };
  const handleResendOTP = () => {
    setTimer(60);
    SendOtp(currentData)
    // Add logic to resend OTP
  };
  const handleResendOTP2 = () => {
    setTimer(60);
    handleRegister(currentData)
    // Add logic to resend OTP
  };
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);
  
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(); setOtpEnable(false); reset2(); setOtp(''); setCurrentState('register'); setConfirmPassword(false)
        setOtpEnable(false)
        setOtpEnable2(false)
        setOtpError(false)
        setOtpError2(false)
        setOtp('')
        setOtp2('')
        reset3()
        reset4()
        reset()
        setEmailConfirmation(false)
      }}
      maxWidth="md"
      // sx={{ ".MuiDialog-container": { height: "auto !important" } }} 
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(8px)",
            backgroundColor: "transparent",
          },
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: {lg:"15px",md:'15px',sm:'0px',xs:'0px'},
          backgroundColor: { lg: "rgba(15, 23, 42, 1)", md: "rgba(15, 23, 42, 1)", sm: "transparent", xs: "transparent" },
          boxShadow: "none",
        },
      }}
    >
      <Grid container sx={{ p: { lg: 3, md: 3, sm: 0, xs: 0 } }}>
        <Grid
          item
          lg={5}
          md={5}
          sm={12}
          xs={12}
       
          sx={{
            backgroundColor: { lg: "rgba(15, 23, 42, 1)", md: "rgba(15, 23, 42, 1)", sm: "transparent", xs: "transparent" },
            color: "white",
            display: "flex",
            alignItems: "center",
            pr: { lg: 3,md: 3, sm:0, xs: 0},
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: currentState != "regitser" ? "flex-end" : "center",
            }}
          >
            {(currentState == "login") && !emailConfirmation &&
              (
                <form onSubmit={handleSubmit(handleLogin)}>
                  <Typography
                    className="heading-font"
                    variant="h5"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    Welcome
                  </Typography>
                  <Typography
                    variant="h6"
                    mb={2}
                    sx={{ textAlign: "center", fontSize: "14px" }}
                  >
                    We are glad to see you back with us
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Email"
                    variant="outlined"
                    type="email"
                    size="small"

                    {...register("login", { required: "Email is required" })}
                    error={!!errors.login}
                    helperText={errors.login ? errors.login.message : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component={"img"}
                            src={Images.emailIcon}
                            width={"17px"}
                          ></Box>
                        </InputAdornment>
                      ),
                      style: {
                        color: "#0F172A",
                        fontSize: "16px",
                        borderRadius: 8, // Rounded corners
                      },
                    }}
                    sx={{
                      mb: 2,
                      backgroundColor: "transparent", // Dark background color
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />

                  <TextField
                    fullWidth
                    placeholder="Password"
                    size="small"
                    variant="outlined"
                    type={showPasswordLogin ? "text" : "password"}
                    {...register("password", {
                      required: "Password is required",
                    })}
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component={"img"}
                            src={Images.passwordIcon}
                            width={"17px"}
                          ></Box>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowPasswordLogin(!showPasswordLogin)
                            }
                            edge="end"
                          >
                            {showPasswordLogin ? (
                              <VisibilityIcon
                                sx={{ color: "#0F172A", fontSize: "20px" }}
                              />
                            ) : (
                              <VisibilityOffIcon
                                sx={{ color: "#0F172A", fontSize: "20px" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        color: "#0F172A",
                        fontSize: "16px",
                        borderRadius: 8, // Rounded corners
                      },
                    }}
                    sx={{
                      mb: 1,
                      // backgroundColor: "#1E1E1E", // Dark background color
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', fontSize: '13px' }}>
                    <span style={{ textAlign: 'right' }} onClick={() => setEmailConfirmation(true)}>Forget Password?</span>
                  </Box>
                  {error && (
                    <span
                      style={{
                        color: "#d32f2f",
                        fontSize: "12px",
                        marginBottom: "10px",
                      }}
                    >
                      {error}
                    </span>
                  )}
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#0EA5EA",
                      color: "#22272d",
                      padding: "6px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      borderRadius: 2,
                      textTransform: "capitalize",
                      mt: 1,
                      "&:hover": { backgroundColor: "#0EA5EA" },
                    }}
                  >
                    Login
                  </Button>
                </form>
              )}
            {emailConfirmation && (
              <>
                <Box sx={{ width: "100%" }} component={'form'} onSubmit={handleSubmit3(SendOtp)}>
                <Typography
                    className="heading-font"
                    variant="h5"
                    mb={2}
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontFamily: "Plus Jakarta Sans",
                
                    }}
                  >
                    Enter Email
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Email"
                    variant="outlined"
                    type="email"
                    size="small"

                    {...register3("email", { required: "Email is required" })}
                    error={!!errors3.email}
                    helperText={errors3.email ? errors3.email.message : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component={"img"}
                            src={Images.emailIcon}
                            width={"17px"}
                          ></Box>
                        </InputAdornment>
                      ),
                      style: {
                        color: "#0F172A",
                        fontSize: "16px",
                        borderRadius: 8, // Rounded corners
                      },
                    }}
                    sx={{
                      mb: 2,
                      // backgroundColor: "#1E1E1E", // Dark background color
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />
                  {emailError && <span style={{ color: 'red', marginTop: '5px', fontSize: '12px' }}> &nbsp; User Not Found </span>}
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: "#0EA5EA",
                      color: "#22272d",
                      padding: "6px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      borderRadius: 2,
                      textTransform: "capitalize",
                      mt: 3,

                      "&:hover": { backgroundColor: "#0EA5EA" },
                    }}

                  >
                    Submit
                  </Button>{" "}
                </Box>
              </>
            )}
            {confirmPassword && (
              <>
                <Box sx={{ width: "100%" }} component={'form'} onSubmit={handleSubmit4(UpdatePassword)}>
                <Typography
                    className="heading-font"
                    variant="h5"
                    mb={2}
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontFamily: "Plus Jakarta Sans",
                
                    }}
                  >
                    Reset Password
                  </Typography>
                  {/* Password Field */}
                  <TextField
                    fullWidth
                    placeholder="Password"
                    variant="outlined"
                    size="small"
                    type={showPassword ? "text" : "password"}
                    {...register4("password", {
                      required: "Password is required",
                    })}
                    error={!!errors4.password}
                    helperText={
                      errors4.password ? errors4.password.message : ""
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component={"img"}
                            src={Images.passwordIcon}
                            width={"17px"}
                          ></Box>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPasswordLogin ? (
                              <VisibilityIcon
                                sx={{ color: "#0F172A", fontSize: "20px" }}
                              />
                            ) : (
                              <VisibilityOffIcon
                                sx={{ color: "#0F172A", fontSize: "20px" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        color: "#0F172A",
                        fontSize: "16px",
                        borderRadius: 8, // Rounded corners
                      },
                    }}
                    sx={{
                      my: 1,
                      backgroundColor: "#1E1E1E", // Dark background color
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "&.MuiFormControl-fullWidth": {
                        background: "transparent !important",
                        borderRadius: '10px !important'
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />

                  {/* Confirm Password Field */}
                  <TextField
                    fullWidth
                    placeholder="Confirm Password"
                    size="small"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    {...register4("confirmPassword", {
                      required: "Confirm Password is required",
                      validate: (value) =>
                        value === getValues4("password") ||
                        "Passwords do not match",
                    })}
                    error={!!errors4.confirmPassword}
                    helperText={
                      errors4.confirmPassword
                        ? errors4.confirmPassword.message
                        : ""
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component={"img"}
                            src={Images.passwordIcon}
                            width={"17px"}
                          ></Box>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword2(!showPassword2)}
                            edge="end"
                          >
                            {showPasswordLogin ? (
                              <VisibilityIcon
                                sx={{ color: "#0F172A", fontSize: "20px" }}
                              />
                            ) : (
                              <VisibilityOffIcon
                                sx={{ color: "#0F172A", fontSize: "20px" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        color: "#0F172A",
                        fontSize: "16px",
                        borderRadius: 8, // Rounded corners
                      },
                    }}
                    sx={{
                      my: 1,
                      backgroundColor: "#1E1E1E", // Dark background color
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "&.MuiFormControl-fullWidth": {
                        background: "transparent !important",
                        borderRadius: '10px !important'
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />

                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: "#0EA5EA",
                      color: "#22272d",
                      padding: "6px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      borderRadius: 2,
                      textTransform: "capitalize",
                      mt: 3,

                      "&:hover": { backgroundColor: "#0EA5EA" },
                    }}

                  >
                    Submit
                  </Button>{" "}
                </Box>
              </>
            )}
            {(currentState == "register") && !emailConfirmation &&

              !otpEnable && !otpEnable2 && (
                <form onSubmit={handleSubmit2(handleRegister)}>
                  {/* Name Field */}
                  <Typography
                    className="heading-font"
                    variant="h5"
                    mb={2}
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontFamily: "Plus Jakarta Sans",
                     
                   
                    }}
                  >
                    Signup now
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Full Name"
                    variant="outlined"
                    size="small"
                    {...register2("name", { required: "Name is required" })}
                    error={!!errors2.name}
                    helperText={errors2.name ? errors2.name.message : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component={"img"}
                            src={Images.nameIcon}
                            width={"17px"}
                          ></Box>
                        </InputAdornment>
                      ),
                      style: {
                        color: "#0F172A",
                        fontSize: "16px",
                        borderRadius: 8, // Rounded corners
                      },
                    }}
                    sx={{
                      my: 1,
                      // backgroundColor: "#1E1E1E", // Dark background color
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "&.MuiFormControl-fullWidth": {
                        background: "transparent !important",
                        borderRadius: '10px !important'
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />

                  {/* Email Field */}
                  <TextField
                    fullWidth
                    placeholder="Email"
                    variant="outlined"
                    size="small"
                    type="email"
                    {...register2("email", { required: "Email is required" })}
                    error={!!errors2.email}
                    helperText={errors2.email ? errors2.email.message : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component={"img"}
                            src={Images.emailIcon}
                            width={"17px"}
                          ></Box>
                        </InputAdornment>
                      ),
                      style: {
                        color: "#0F172A",
                        fontSize: "16px",
                        borderRadius: 8, // Rounded corners
                      },
                    }}
                    sx={{
                      my: 1,
                      backgroundColor: "#1E1E1E", // Dark background color
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "&.MuiFormControl-fullWidth": {
                        background: "transparent !important",
                        borderRadius: '10px !important'
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />

                  {/* Password Field */}
                  <TextField
                    fullWidth
                    placeholder="Password"
                    variant="outlined"
                    size="small"
                    type={showPassword ? "text" : "password"}
                    {...register2("password", {
                      required: "Password is required",
                    })}
                    error={!!errors2.password}
                    helperText={
                      errors2.password ? errors2.password.message : ""
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component={"img"}
                            src={Images.passwordIcon}
                            width={"17px"}
                          ></Box>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPasswordLogin ? (
                              <VisibilityIcon
                                sx={{ color: "#0F172A", fontSize: "20px" }}
                              />
                            ) : (
                              <VisibilityOffIcon
                                sx={{ color: "#0F172A", fontSize: "20px" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        color: "#0F172A",
                        fontSize: "16px",
                        borderRadius: 8, // Rounded corners
                      },
                    }}
                    sx={{
                      my: 1,
                      backgroundColor: "#1E1E1E", // Dark background color
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "&.MuiFormControl-fullWidth": {
                        background: "transparent !important",
                        borderRadius: '10px !important'
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />

                  {/* Confirm Password Field */}
                  <TextField
                    fullWidth
                    placeholder="Confirm Password"
                    size="small"
                    variant="outlined"
                    type={showPassword2 ? "text" : "password"}
                    {...register2("confirmPassword", {
                      required: "Confirm Password is required",
                      validate: (value) =>
                        value === getValues2("password") ||
                        "Passwords do not match",
                    })}
                    error={!!errors2.confirmPassword}
                    helperText={
                      errors2.confirmPassword
                        ? errors2.confirmPassword.message
                        : ""
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component={"img"}
                            src={Images.passwordIcon}
                            width={"17px"}
                          ></Box>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword2(!showPassword2)}
                            edge="end"
                          >
                            {showPasswordLogin ? (
                              <VisibilityIcon
                                sx={{ color: "#0F172A", fontSize: "20px" }}
                              />
                            ) : (
                              <VisibilityOffIcon
                                sx={{ color: "#0F172A", fontSize: "20px" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        color: "#0F172A",
                        fontSize: "16px",
                        borderRadius: 8, // Rounded corners
                      },
                    }}
                    sx={{
                      my: 1,
                      backgroundColor: "#1E1E1E", // Dark background color
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        "& fieldset": { borderColor: "transparent" }, // Blue border
                        "&:hover fieldset": { borderColor: "transparent" },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "&.MuiFormControl-fullWidth": {
                        background: "transparent !important",
                        borderRadius: '10px !important'
                      },
                      "& .MuiInputLabel-root": { color: "black" },
                      "& .MuiInputLabel-root.Mui-focused": { color: "black" },
                    }}
                  />

                  {/* Submit Button */}
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#0EA5EA",
                      color: "#22272d",
                      padding: "6px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      borderRadius: 2,
                      textTransform: "capitalize",
                      mt: 1,

                      "&:hover": { backgroundColor: "#0EA5EA" },
                    }}
                  >
                    Register
                  </Button>
                </form>
              )}
            {otpEnable && (
              <>
                <Typography
                  className="heading-font"
                  variant="h5"
                  mb={2}
                  sx={{
                    fontWeight: 600,
                    textAlign: "center",
                    fontFamily: "Plus Jakarta Sans",
                  }}
                >
                  Enter OTP
                </Typography>
                <div className="otp-container">
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span className="separator">-</span>}
                    renderInput={(props) => (
                      <input className="otp-input" {...props} />
                    )}
                  />
                </div>{" "}
                {otpError && <span style={{ color: 'red', marginTop: '5px', fontSize: '12px' }}> &nbsp; OTP is Invalid </span>}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
      <span style={{ fontSize: "14px", color: "#6B7280" }}>
        Resend OTP in {timer}s
      </span>
      <Button
        variant="text"
        disabled={timer > 0}
        onClick={handleResendOTP2}
        sx={{
          fontSize: "14px",
          textTransform: "capitalize",
          color: timer > 0 ? "#9CA3AF" : "#0EA5EA",
        }}
      >
        Resend
      </Button>
    </div>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={otp?.length < 4}
                  sx={{
                    backgroundColor: "#0EA5EA",
                    color: "#22272d",
                    padding: "6px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderRadius: 2,
                    textTransform: "capitalize",
                    mt: 3,

                    "&:hover": { backgroundColor: "#0EA5EA" },
                  }}
                  onClick={() => SubmitOTP()}
                >
                  Submit
                </Button>{" "}
              </>
            )}
            {otpEnable2 && (
  <>
    <Typography
      className="heading-font"
      variant="h5"
      mb={2}
      sx={{
        fontWeight: 600,
        textAlign: "center",
        fontFamily: "Plus Jakarta Sans",
      }}
    >
      Enter OTP
    </Typography>
    <div className="otp-container">
      <OTPInput
        value={otp2}
        onChange={setOtp2}
        numInputs={4}
        renderSeparator={<span className="separator">-</span>}
        renderInput={(props) => (
          <input className="otp-input" {...props} />
        )}
      />
    </div>
    {otpError2 && (
      <span style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
        &nbsp; OTP is Invalid
      </span>
    )}
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
      <span style={{ fontSize: "14px", color: "#6B7280" }}>
        Resend OTP in {timer}s
      </span>
      <Button
        variant="text"
        disabled={timer > 0}
        onClick={handleResendOTP}
        sx={{
          fontSize: "14px",
          textTransform: "capitalize",
          color: timer > 0 ? "#9CA3AF" : "#0EA5EA",
        }}
      >
        Resend
      </Button>
    </div>
    <Button
      fullWidth
      variant="contained"
      disabled={otp2?.length < 4}
      sx={{
        backgroundColor: "#0EA5EA",
        color: "#22272d",
        padding: "6px",
        fontSize: "16px",
        fontWeight: "bold",
        borderRadius: 2,
        textTransform: "capitalize",
        mt: 3,
        "&:hover": { backgroundColor: "#0EA5EA" },
      }}
      onClick={() => SubmitOTP2()}
    >
      Submit
    </Button>
  </>
)}

            {!otpEnable && !otpEnable2 && currentState == "login" && !emailConfirmation && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ my: 3 }}
              >
                <Box
                  sx={{
                    height: "1px",
                    backgroundColor: "white",
                    flex: 1,
                    mr: 2,
                  }}
                ></Box>
                <Typography variant="body2" sx={{ color: "white" }}>
                  Or Continue with
                </Typography>
                <Box
                  sx={{
                    height: "1px",
                    backgroundColor: "white",
                    flex: 1,
                    ml: 2,
                  }}
                ></Box>
              </Box>
            )}

            {!otpEnable && !otpEnable2 && currentState == "login" && !emailConfirmation && (
              <Box
                spacing={2}
                display={"flex"}
                justifyContent="center"
                alignItems="center"
                gap={2}
                sx={{ mt: 2 }}
              >
                <Button
                  onClick={() => {
                    setCurrentState('register');
                    setOtp('')
                    setOtp2('')
                    handleClose();
                    setOtpError(false)
                    setOtpError2(false)
                    handleGoogleLogin();
                  }}
                  variant="contained"
                  sx={{
                    backgroundColor: "transparent",
                    color: "white",
                    padding: "12px 8px",
                    border: "1px solid white !important",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    textTransform: "lowercase",
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "12px",
                    // width:'100%',
                    ":hover": {
                      backgroundColor: "transparent",
                    }
                  }}
                >
                  <Box
                    component={"img"}
                    src={Images.googleIcon}
                    width={"30px"}
                  />

                </Button>
                {/* <Button
                  // onClick={() => {
                  //     handleClose();
                  //     handleGoogleLogin();
                  // }}

                  variant="contained"
                  sx={{
                    backgroundColor: "transparent",
                    color: "white",
                    padding: "12px 8px",
                    border: "1px solid white !important",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    textTransform: "capitalize",
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "12px",
                    // width:'100%',
                    // mt:1,
                    ":hover":{
                      backgroundColor: "transparent",
                    }
                  }}
                >
                  <Box
                    component={"img"}
                    src={Images.instaIcon}
                    width={"30px"}
                  />
                 
                </Button> */}
              </Box>
            )}
            {!otpEnable && !otpEnable2 && !emailConfirmation && !confirmPassword && currentState == "register" ? (
              <Typography
                variant="body2"
                sx={{ color: "#0EA5EA", textAlign: "center", mt: 2 }}
              >
                Already have an account?{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#0EA5EA",
                  }}
                  onClick={() => setCurrentState("login")}
                >
                  Login
                </span>
              </Typography>
            ) : (
              !otpEnable && !otpEnable2 && !emailConfirmation && !confirmPassword && (
                <Typography
                  variant="body2"
                  sx={{ color: "#0EA5EA", textAlign: "center", mt: 2 }}
                >
                  Don't have an account?{" "}
                  <span
                    className="heading-font"
                    style={{
                      fontWeight: "bold",
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#0EA5EA",
                    }}
                    onClick={() => setCurrentState("register")}
                  >
                    Signup now
                  </span>
                </Typography>
              )
            )}
          </Box>
        </Grid>

        <Grid
      item
      xs={7}
      sx={{
        backgroundColor: "#0F172A",
        display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLoading && (
        <Box component={'img'} src={Images.loader} width={'200px'} height={'200px'} />
      )}
      <Box
        component="img"
        src={Images.modalPic}
        width={"95%"}
        sx={{ margin: "0 auto", display: isLoading ? "none" : "block" }}
        onLoad={handleImageLoad}
        alt="Modal Content"
      />
    </Grid>
      </Grid>
    </Dialog>
  );
};

export default SignInModal;
