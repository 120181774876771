import { CancelOutlined } from '@mui/icons-material';
import { Box, Button, Dialog, IconButton, TextField, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { red } from '@mui/material/colors';
import { useEffect, useState } from 'react';
function ConfirmationDialog({ open, onClose, message, action, warning, singleButton,Input,deleteBox,overwrite,existingValue }) {

  const shirtDesigns = [
    "Abstract Waves", "Mountain Peaks", "Sunset Vibes", "Retro Sunset", "Galactic Dreams", "Cosmic Galaxy", "Urban Jungle", "Neon Lights", "Techno Bliss", "Eclipse Overdrive",
    "Colorful Splash", "Zen Garden", "Skull & Roses", "Boho Spirit", "Tribal Flames", "Desert Mirage", "Solar Flare", "Dream Catcher", "Animal Instinct", "Retro Space",
    "Funky Patterns", "Ocean Breeze", "Retro Wave", "Golden Hour", "Sunset Horizon", "Peace & Love", "Astronaut Adventure", "Geometric Balance", "Celestial Moon", "Pop Art Explosion",
    "Rising Sun", "Urban Graffiti", "Mindful Moments", "Mystic Forest", "Dinosaur Adventures", "Polaroid Memories", "Vivid Dreams", "Into the Wild", "Space Invaders", "Surf's Up",
    "Street Culture", "Tropical Paradise", "Electric Vibes", "Mystic Mountains", "Wild at Heart", "Urban Safari", "Boho Mandala", "Neon Dreams", "Cosmic Creatures", "Tribal Patterns",
    "Sunset Waves", "Floral Bliss", "Electric Jungle", "Abstract Geometry", "Peaceful Path", "Vintage Rock", "Modern Minimalism", "Lost in Space", "Floral Dreams", "Solar Eclipse",
    "Cactus Desert", "Ocean Waves", "Pastel Paradise", "Tech Jungle", "Tropical Escape", "Space Odyssey", "Psychadelic Vibes", "Cyberpunk City", "Black & White Serenity", "Funky Animals",
    "Waves of Wonder", "Cosmic Vibes", "Geometric Explosion", "Earth Elements", "Skyline Silhouettes", "Sun & Moon", "Underwater Wonders", "City Dreams", "Autumn Leaves", "Vivid Vision",
    "Wanderlust", "Summer Blooms", "Neon Escape", "Midnight Adventure", "Retro Rewind", "Moonlight Serenity", "Abstract Art", "Galactic Voyage", "Starry Night", "Wildflower Field",
    "Tropical Storm", "Dreamy Beach", "Electric Pulse", "Cosmic Harmony", "Zen Waves", "Earth & Sky", "Mysterious Creatures", "Creative Chaos", "Abstract Nature", "Sunrise Glow",
    "Woodland Wonders", "Star Wars", "Peaceful Wanderer", "Oceanic Vibes", "Adventure Awaits", "Animal Kingdom", "Cityscape Dreams", "Electric Escape", "Vintage Love", "Cultural Fusion",
    "Wild Soul", "Retro Flames", "Cosmic Surf", "Animal Spirit", "Natural Beauty", "Vintage Space", "Trippy Patterns", "Street Culture Art", "Mystical Beings", "Sunset Glow",
    "Urban Chaos", "Geometric Adventure", "Moonlit Path", "Earth Wanderer", "Neon Paradise", "Abstract Fusion", "Digital Skies", "Retro Animals", "Mysterious Sunset", "Nature's Grace",
    "Tribal Spirit", "Ocean Horizon", "Night Sky", "Stardust Dreams", "Wild Wonders", "Neon Nights", "Forest Magic", "Jungle Safari", "Galactic Odyssey", "Electric Bloom",
    "Moon Phases", "Abstract Flow", "Desert Storm", "Mountain Trek", "Holographic Dreams", "Cosmic Love", "Surreal Skies", "Wild and Free", "Inner Peace", "Retro Sun",
    "Tropical Vibes", "Galaxy Wanderer", "Cultural Essence", "Trippy Dreams", "Cosmic Creatures", "Mystical Moon", "Summer Heat", "Retro Pop", "Lunar Path", "Space Quest",
    "Starry Skies", "Nature Spirit", "Cosmic Bloom", "Mountain Dreamer", "Artistic Vibes", "Electric Forest", "Wanderer's Soul", "Tropical Paradise", "Under the Stars", "Horizon Glow",
    "Boho Chic", "Celestial Wonders", "Bohemian Vibes", "Surreal Landscape", "City Lights", "Sunrise Waves", "Mystical Forest", "Colorful Chaos", "Zen Dreams", "Cosmic Energy",
    "Ocean Breeze", "Desert Blooms", "Space Bound", "Electric Horizons", "Artistic Peace", "Lunar Eclipse", "City Jungle", "Nature's Vibes", "Abstract Dreams", "Astral Vibes",
    "Wild Nature", "Tribal Echoes", "Cosmic Adventures", "Endless Horizon", "Oceanic Journey", "Skull Dreams", "Zen Vibes", "Boho Patterns", "Urban Dreamer", "Retro Waves",
    "Desert Vision", "Neon Pulse", "Tropical Mystique", "Starry Dreams", "Ethereal Glow", "Electric Jungle", "Into the Night", "Boho Beach", "Moonlit Journey", "Mystical Universe",
    "Urban Legends", "Cosmic Wilderness", "Mountain Escape", "Electric Tribe", "Space Dreams", "Neon Stripes", "Wild Spirits", "Tribal Quest", "Serene Nature", "Geometric Jungle",
    "Mystic Moon", "Retro Nature", "Cosmic Bloom", "Neon Dreams", "Ocean Voyage", "Electric Escape", "Boho Desert", "Tropical Wave", "Abstract Universe", "Midnight Sky",
    "Galactic Journey", "Celestial Path", "Desert Dance", "Peaceful Dreams", "Cosmic Soul", "Artistic Wave", "Mystic Journey", "Electric Safari", "Tribal Rhythm", "Vivid Reality",
    "Boho Dreamer", "Mountain Wanderer", "Retro Love", "Urban Skies", "Lunar Bliss", "Oceanic Escape", "Abstract Love", "Cosmic Patterns", "Tropical Zen", "Space Rhythm",
    "Retro Horizon", "Wild Desert", "Moonlit Bliss", "Electric Jungle", "Vibrant Adventures", "Artistic Waves", "Celestial Skies", "Cosmic Escape", "Neon Jungle", "Surreal Mind",
    "Tropical Bloom", "Geometric Flow", "Mountain Bliss", "Jungle Escape", "Abstract Love", "Cosmic Wave", "City Life", "Tribal Energy", "Electric Bloom", "Space Adventures",
    "Vibrant Galaxy", "Night Vibes", "Retro Dreams", "Urban Adventure", "Moonlit Dreams", "Electric Dreams", "Cosmic Spirit", "Starry Wonderland", "Artistic Escape", "Wild Quest",
    "Neon Pathways", "Mystic Colors", "Space Wanderer", "Galactic Soul", "Retro Creatures", "Cosmic Vision", "Geometric Escape", "Electric Wonders", "Sunset Dreams", "Ocean Adventure",
    "Desert Sunrise", "Space Blues", "Sunset Spirit", "Lunar Landscapes", "City Lights", "Electric Skies", "Cosmic Wanderer", "Tropical Rain", "Jungle Bliss", "Mountain Peak",
    "Vivid Creatures", "Abstract Waves", "Floral Bliss", "Cosmic Reflection", "Electric Tides", "Tropical Glow", "Moonlit Vibes", "Sunset Mirage", "Celestial Dream", "Geometric Jungle",
    "Astronaut Dreams", "Boho Escape", "Nature's Rhythm", "Electric Skies", "Tribal Nature", "Cosmic Heart", "Wild Adventure", "Geometric Fusion", "Bohemian Waves", "Trippy Bliss",
    "Galactic Dreams", "Lunar Escape", "Space Escape", "Tribal Spirits", "Retro Earth", "Neon Voyage", "Cosmic Reflections", "Nature's Path", "Electric Vibes", "Urban Vibes",
    "Sunset Safari", "Cosmic Hues", "Mystic Journeys", "Tropical Adventures", "Nature's Beauty", "Boho Retreat", "Sunset Escape", "Neon Tides", "Astral Waves", "City Pulse",
    "Cosmic Adventure", "Nature's Escape", "Boho Pulse", "Electric Mind", "Surreal Desert", "Space Jungle", "Neon Pulse", "Retro Jungle", "Electric Bliss", "Tropical Nights"
  ];
  const [inputValue, setInputValue] = useState(existingValue);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleActionClick = () => {
    action(inputValue); // Pass the input value to the action function
  };
  useEffect(() => {
    if(!existingValue){

      const randomNumber = Math.floor(Math.random() * 300) + 1;
      console.log(randomNumber, 'randomNumber');
      setInputValue(shirtDesigns[randomNumber])
    }
  }, [open])

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
           width: warning ? {lg:'50%',md:'70%',sm:'80%',xs:'80%'}: {lg:'30%',md:'70%',sm:'80%',xs:'80%'},
          height: "auto",
          borderRadius: 2,
          py: { xs: 2, md: 4 },
          px: { xs: 3, md: 6 },
          backgroundColor: '#0F172A'
        },

      }}
    >
      <IconButton onClick={onClose} sx={{ position: 'absolute', right: 13, top: 13 }}>
        <CancelOutlined sx={{ color: 'white' }} />
      </IconButton>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {warning && <WarningIcon sx={{ color: red[500], fontSize: '50px !important' }} />}
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography className='heading-font2' variant="h6" sx={{ color: 'white', mt: 1, mb: 1.5 }}>
          {message}
        </Typography>
        {Input != false && !deleteBox  &&  <TextField
          fullWidth
          size="small"
          variant="outlined"
          placeholder="Enter Design Name"
          value={inputValue}
          onChange={handleInputChange}
          sx={{
            mt: 2,
            mb: 2,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white', // Border color
              },
              '&:hover fieldset': {
                borderColor: 'white', // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white', // Border color when focused
              },
              color: 'white', // Text color
            },
            '& .MuiInputBase-input': {
              color: 'white', // Text color for input text
            },
            '& .MuiInputLabel-root': {
              color: 'white', // Placeholder color
            },
          }}
          InputProps={{
            style: {
              color: 'white', // Input text color
            },
          }}
        />}

        <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
          {!singleButton ? <Box sx={{display:'flex',gap:2}}><Button
            title={deleteBox ? "Yes" : overwrite ? 'Save As':'Save'}
            onClick={handleActionClick}
            style={{ backgroundColor: '#0cbadd', color: "#0F172A", textTransform: 'capitalize' }}
          >
            {deleteBox ? "Yes" : overwrite ? 'Save As':'Save'}
          </Button>
          <Button
            title="Cancel"
            onClick={onClose}
            style={{ backgroundColor: '#0cbadd', color: "#0F172A", textTransform: 'capitalize' }}
          >
            Cancel
          </Button> </Box>  :
            <Button
              title="Continue"
              onClick={handleActionClick}
              style={{ backgroundColor: '#0cbadd', color: "#0F172A", textTransform: 'capitalize' }}
            >
              Continue
            </Button>}
        </Box>
      </Box>
    </Dialog>
  )
}

export default ConfirmationDialog