import React, { useContext, useState } from 'react';
import { Box, Button, CardMedia, Container, Grid, IconButton, ImageList, ImageListItem, Modal, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';


import { East } from '@mui/icons-material';
import { Images } from '../../assests';
import Colors from '../../assests/style';
import moment from 'moment';
import { getAuth, signInWithPopup } from 'firebase/auth';
import AuthServices from '../../services/AuthServices';
import { provider } from '../../config/firebase.config';
import { AuthContext } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SecondaryButton } from '../../components/Buttons';
import Swal from 'sweetalert2';
import SignInModal from '../../components/LoginModal';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif'// You can change this to your preferred font
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: 'rgba(255, 255, 255, 0.7)',
          fontFamily: 'Poppins, sans-serif',
          '&.Mui-active': {
            color: '#ffffff',
          },
          '&.Mui-completed': {
            color: '#ffffff',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            color: 'white', // Text color,
            fontFamily: 'Poppins, sans-serif'
          },
          '& .MuiInputLabel-root': {
            color: 'white', // Label color
          },
          '& .MuiInput-underline:before': {
            borderBottomColor: 'white', // Underline color when unfocused
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'white', // Underline color when focused
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white', // Border color when unfocused
            },
            '&:hover fieldset': {
              borderColor: 'white', // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white', // Border color when focused
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: 'white', // Text color for input
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'white', // Border color for input
        },
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'white', // Text color for label
          borderColor: 'white',
          '&.Mui-focused': {
            color: 'white',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'black', // Text color for menu items
          backgroundColor: 'white', // Background color for menu items
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)', // Background color for selected item
          },
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Background color for hover
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: 'white', // Color for dropdown arrow
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#1976d2',
    },
    background: {
      default: '#333333',
    },
  },

});

function Footer() {
  const auth = getAuth();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [currentState, setCurrentState] = useState("login");
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    formState: { errors: errors2 },
  } = useForm();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = data => {
    // Handle form submission
    console.log(data);
    handleClose(); // Close the modal after submission
  };
  let User = localStorage.getItem('user')
  User = JSON.parse(User)
  const navigate = useNavigate()
  const { user, setUser } = useContext(AuthContext);
  console.log(user, 'contextdata');
  const [userData, setUserData] = useState()
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  let itemData = [{ img: Images.collection1 }, { img: Images.collection2 }, { img: Images.collection3 }, { img: Images.collection4 }]
  const handleGoogleLogin = async () => {

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("User Info: ", user);


      authLogin(user?.reloadUserInfo, result)
      // Handle user info here (e.g., save to state, context, or redirect)
    } catch (error) {
      console.error("Error during Google login: ", error);
    }
  };

  const authLogin = async (sendData, result) => {
    console.log(sendData, 'data');
    try {
      let obj = {
        phone: '',
        google_id: sendData.localId,
        email: sendData.email,
     
        name: sendData.displayName
      }
      const data = await AuthServices.authLogin(obj)
      console.log(data);
      if (data.responseCode == 200) {

        localStorage.setItem('authToken', JSON.stringify(data?.data?.token))
        localStorage.setItem('user', JSON.stringify(sendData))
        let userInfo = localStorage.getItem('user')
        setUserData(JSON.parse(userInfo))
        setUser(result.user)
      }



    } catch (error) {
      JSON.Stringyfy(error)
    } finally {

    }
  }
  const handleClose2 = () => {
    
    setOpen(false);
  };
  const handleState = () => {
    console.log(currentState);
    
    setCurrentState(currentState == 'login' ? 'register' : 'login')
  };
  return (
    <Box
      component={"footer"}
      sx={{
        backgroundColor: Colors.backgroundColor2,
        pt: "60px",
        pb: "20px",
      }}
    >
       <SignInModal
        open={open}
        toggleState={handleState}
        handleClose={handleClose}
        handleSubmit={handleSubmit2}
        register={register}
        screen={currentState}
        errors={errors}
        handleGoogleLogin={handleGoogleLogin}
        Images={{
          mainlogo: "/path/to/mainlogo.png",
          googleIcon: "/path/to/googleIcon.png",
          whiteIcon: "/path/to/whiteIcon.png",
        }}
      />

    
      <Container >
        <Grid
          container

        >
          <Grid item md={12} sm={12} xs={12}>
            <Grid container >
              <Grid item md={3} sm={4} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    alignItems: { xs: "center", sm: "flex-start", md: "flex-start" }
                  }}
                >
                  <CardMedia component={"img"} src={Images.mainlogo} sx={{ width: { lg: "60%", md: "60%", sm: "60%", xs: '60%' }, objectFit: "contain", mt: { lg: '-20px', md: '-20px', sm: '-10px', xs: 0 } }} />


                  <Typography
                    sx={{ color: Colors.textColor1, fontSize: '14px', cursor: 'pointer',fontWeight:'0.875rem' }}
                    component="a"
                    href="mailto:coolcat@printmeup.ai"
                  >
                    &nbsp; coolcat@printmeup.ai
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6} sm={4} xs={12}>
                <Typography className='heading-font' sx={{ color: '#0EA5EA', fontWeight: 600, textAlign: 'center', mt: { md: 0, sm: 0, xs: 2 } }}>Useful Links</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { lg: "row", md: 'row', sm: "column", xs: "column" },
                    gap: "8px",
                    justifyContent: 'center',
                    mt: '10px'
                  }}
                >

                  {<Button sx={{ color: Colors.textColor1, textTransform: "capitalize" }} onClick={() => {
                    let User = localStorage.getItem('user')
                    User = JSON.parse(User)
                    if (User) {
                      window.location.href = '/my-account'

                    }
                    else {
                     setOpen(true)
                    }
                  }}>My Account</Button>}

                  <Button onClick={() => navigate('/privacypolicy')} sx={{ color: Colors.textColor1, textTransform: "capitalize" }}>Privacy Policy</Button>
                  <Button onClick={() => navigate('/termsandconditions')} sx={{ color: Colors.textColor1, textTransform: "capitalize" }}>Terms & Conditions</Button>
                </Box>
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <Typography className='heading-font' sx={{ color: '#0EA5EA', fontWeight: 600, textAlign: { md: 'center', sm: 'center', xs: "center" }, mt: { lg: 0, md: 0, sm: 0, xs: 1 } }}>Support</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { lg: "row", md: 'row', sm: "column", xs: "column" },
                    gap: "8px",
                    mt: '10px',
                    justifyContent: "center",
                    alignItems: { xs: "center", sm: "center", md: "center" }
                  }}
                >


                  <Button onClick={() => window.location.href = 'contact-us'} sx={{ color: Colors.textColor1, textTransform: "capitalize", p: "6px 8px" }}>Contact Us</Button>

                </Box>
              </Grid>


            </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12} sx={{ mt: 3 }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                {/* <CardMedia component={"img"} src={Images.footerPayment} sx={{ width: "50%", objectFit: "contain" }} /> */}
                <Typography sx={{ color: Colors.white, fontSize: { lg: "18px", md: "18px", sm: "18px", xs: "14px" }, }}>© {moment().format('YYYY')} Print Me Up. All rights reserved.</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer